import React, { ChangeEvent, useMemo } from 'react'
import InfoIcon from '@ubnt/icons/InfoIcon'
import { typography } from '@ubnt/ui-components/styles/designToken/typography'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import { FormikProps, withFormik } from 'formik'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { Input } from 'components/Input'
import { ModalForm } from 'components/ModalForm'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import {
  ErrorData,
  assuranceLevelResetErrors,
  selectErrors,
  selectIsLoading,
  upgradeAssuranceLevel,
} from 'features/auth/modules/assuranceLevel'
import { logout } from 'features/auth/modules/auth'
import { closeVisibleModal } from 'modules/modals'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import styled from 'theme/styled'
import { RootState } from 'types/types'
import Yup from 'validators/yupLocaleConfig'
import SubmitPasswordModalCustomFooter from './SubmitPasswordModalCustomFooter'

interface Props extends ModalProps {
  isLoading?: boolean
  apiErrors: ErrorData | null
}

interface DispatchProps {
  upgradeAssuranceLevel: typeof upgradeAssuranceLevel
}

interface FormValues {
  password: string
}
const {
  api: { account },
} = __CONFIG__

const PasswordModal: React.FC<
  Props & FormikProps<FormValues> & WrappedComponentProps
> = ({
  isOpen,
  onClose,
  intl,
  values,
  handleSubmit,
  handleBlur,
  handleChange,
  touched,
  apiErrors,
  errors,
}) => {
  const isLoading = useSelector(selectIsLoading)
  const { profileData } = useSsoProfileQuery()

  const dispatch = useDispatch()
  const history = useHistory()

  const errorMessage = useMemo(() => {
    if (apiErrors) {
      return apiErrors?.ERRCODE
        ? intl.formatMessage({ id: apiErrors?.ERRCODE })
        : apiErrors.detail
    }
  }, [apiErrors, intl])

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={() => {
        dispatch(assuranceLevelResetErrors())
        dispatch(closeVisibleModal())
      }}
      title={intl.formatMessage({ id: 'SETTINGS_LOCK_PASSWORD_MODAL_TITLE' })}
      size="small"
      footer={
        <SubmitPasswordModalCustomFooter
          isLoading={isLoading}
          onClose={onClose}
          handleSubmit={handleSubmit}
          logout={logout}
        />
      }
    >
      <StyledDescription>
        <FormattedMessage id="SETTINGS_LOCK_PASSWORD_MODAL_DESCRIPTION" />
        {profileData?.email && (
          <TextWrapper>
            <Label>
              <FormattedMessage id="COMMON_LABEL_EMAIL" />
            </Label>
            <Text>{profileData.email}</Text>
          </TextWrapper>
        )}
      </StyledDescription>
      <ModalForm onSubmit={handleSubmit}>
        <Input
          type="password"
          name="password"
          variant="secondary"
          label={intl.formatMessage({ id: 'COMMON_SSO_LABEL_PASSWORD' })}
          value={values.password || ''}
          disabled={isLoading}
          invalid={(touched.password && errorMessage) || errors.password}
          onChange={(e: ChangeEvent) => {
            if (apiErrors) dispatch(assuranceLevelResetErrors())
            handleChange(e)
          }}
          onBlur={handleBlur}
          autoComplete="current-password"
          bottomMargin={false}
          passwordToggle
          focus
          full
        />
      </ModalForm>
      <ForgotPasswordOuterWrapper>
        <ForgotPasswordWrapper
          onClick={() =>
            history.push(
              `${account.paths.logout}/?redirect=${account.base}${account.paths.resetPassword}`
            )
          }
        >
          <FormattedMessage id="COMMON_AUTH_FORGOT_PASSWORD_TITLE" />
        </ForgotPasswordWrapper>
        <StyledTooltip
          width={240}
          description={
            <FormattedMessage id="SETTINGS_LOCK_PASSWORD_MODAL_FORGOT_PASSWORD_TOOLTIP" />
          }
        >
          <InfoIcon size="original" isActive />
        </StyledTooltip>
      </ForgotPasswordOuterWrapper>
    </GenericModal>
  )
}

const enchance = withFormik<Props & DispatchProps, FormValues>({
  handleSubmit: (values, { props: { upgradeAssuranceLevel } }) =>
    values.password &&
    upgradeAssuranceLevel &&
    upgradeAssuranceLevel(values.password),
  validationSchema: Yup.object().shape({
    password: Yup.string().required().label('COMMON_SSO_LABEL_PASSWORD'),
  }),
})

const SubmitPasswordModalEnchanced = enchance(injectIntl(PasswordModal))

export const SUBMIT_PASSWORD_MODAL_ID = 'SUBMIT_PASSWORD_MODAL_ID'

const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
})

const mapDispatchToProps = {
  upgradeAssuranceLevel,
}

export const WrappedSubmitPasswordModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ upgradeAssuranceLevel, apiErrors }) => (
  <ModalWrapper modalId={SUBMIT_PASSWORD_MODAL_ID}>
    <SubmitPasswordModalEnchanced
      upgradeAssuranceLevel={upgradeAssuranceLevel}
      apiErrors={apiErrors}
    />
  </ModalWrapper>
))

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.text2};
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: left;
  min-width: 0;
`

const Label = styled.span`
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme }) => theme.text3};
  height: 20px;
  display: flex;
  align-items: center;
`

const Text = styled.span`
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.text2};
  height: 32px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const ForgotPasswordWrapper = styled.span`
  font: ${typography['desktop-typography-body']};
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.ublue06};
  &:hover {
    color: ${({ theme }) => theme.ublue04};
  }
`

const ForgotPasswordOuterWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`

const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  margin-left: 4px;
`
